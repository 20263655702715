import _ from "core-cmp/lodash";
import Toastr from "core-cmp/Toastr";
import InfoPanel from "core-uda/ui/InfoPanel";
import {RIGHTS} from "core-uda/Rights";
import {DATA_AUTHORIZED} from "core-uda/Rights";
import ChangePasswordPage from "core-uda/ui/settings/ChangePasswordPage";
import SendMessagePage from "core-uda/ui/settings/SendMessagePage";
import UserInfoPanelTplStache from "thm/ui/common/user/UserInfoPanelTpl.stache";
import Customer from "core-uda/model/customer/Customer";
import UserGroup from "core-uda/model/userGroup/UserGroup";
import LdapUser from "core-uda/model/ldapUser/LdapUser";
import {APP} from "core-uda/model/Resource";
import MaskFormat from "core-cmp/format/MaskFormat";
import {I18N} from "core-cmp/util/I18n";
import "thm/ui/common/user/UserInfoPanel.css";
import $ from "core-cmp/cmp-jquery";
import UserGroupConfirmationPopup from "thm/ui/common/userGroup/UserGroupConfirmationPopup";

/**
 * User form.
 * @class
 * @extends InfoPanel
 */
let UserInfoPanel = InfoPanel.extend({
  template:   UserInfoPanelTplStache,
  i18nPrefix: ['thm.ui.common.user.UserInfoPanel.', 'uda.user.', 'thm.ui.common.userGroup.UserGroupInfoPanel.'],
  id:         'userInfoPanel',
  data:       null,
  entityId:   'user',
  titleKey:   "title",
  allowClone: true,

  /**
   * @override
   */
  fieldsToChangeAfterCloning: function (dataModel) {
    const me = this;

    dataModel.attr({
      id:    "",
      login: "",
      email: ""
    });
    me.attr("creation", true);
  },

  /**
   * @override
   */
  init: function (config) {
    let me = this;

    me._super(config);
    me.viewModel.attr("showClient", RIGHTS("showClient"));
    me.viewModel.attr("showUserGroup", RIGHTS("userGroup"));
    me.viewModel.attr("showDeactivation", RIGHTS("data.user.deactivation"));
    me.viewModel.attr('passwordCmpConfig', {
      checkPasswordNum:     0,
      checkPasswordMaj:     0,
      checkPasswordMin:     0,
      checkPasswordSpe:     0,
      checkPasswordMinSize: 0,
      checkPasswordMaxSize: 50
    });

    MaskFormat.IP_FORMAT = new MaskFormat({
      id:         'ip',
      mask:       /[0-9.,]/i,
      maskVerif:  /^((((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\,?)*)-?)*$/,
      formatType: "string",
      i18nKey:    "thm.ui.common.user.UserInfoPanel.error.mask-ip"
    });
  },

  /**
   * @override
   */
  cleanForEqual: function(attr) {
    attr.authorizedZonesOperator = attr.authorizedZonesOperator ?? "union"
    return attr
  },

  getName: function () {
    let me = this;
    return me.data.login;
  },

  initViewModel: function () {
    let me               = this,
        availableSources = [],
        sources          = [];

    me._super();

    me.updateRoleList();

    me.viewModel.attr('tabsOptions', [
      {value: 'definition', text: me.msg('_Info'), icon: 'info'},
      {value: 'restriction', text: me.msg('_RestrictionValidity'), icon: 'lock'}
    ]);

    me.viewModel.attr('restrictionMobileOptions', [
      {
        value: 'all',
        text:  I18N.msg('core-uda.ui.mobilerequest.MobileRequestPage.field.mobileType.options.all'),
        icon:  'check'
      },
      {
        value: 'mobile',
        text:  I18N.msg('core-uda.ui.mobilerequest.MobileRequestPage.field.mobileType.options.mobiles'),
        icon:  'mobile'
      },
      {
        value: 'fleet',
        text:  I18N.msg('core-uda.ui.mobilerequest.MobileRequestPage.field.mobileType.options.fleets'),
        icon:  'fleet'
      }
    ]);

    me.viewModel.attr('restrictionSourceOptions', [
      {
        value: 'all',
        text:  I18N.msg('core.criteria.source.SourceCriteria.field.sourceType.options.all'),
        icon:  'check'
      },
      {
        value: 'source',
        text:  I18N.msg('core.criteria.source.SourceCriteria.field.sourceType.options.selSources'),
        icon:  'source'
      }
    ]);


    if (APP('positionSources')) {
      availableSources = APP('positionSources').getData();
    } else {
      availableSources = APP().getCapabilities('position', 'sources');
    }

    if (!_.isNil(availableSources) && availableSources.length) {
      _.each(availableSources, function (source) {
        sources.push({value: source.code, text: `${source.name} (${source.code})`});
      });

      sources.sort(function (a, b) {
        return a?.text?.toLowerCase() < b?.text?.toLowerCase() ? -1 : 1
      });
    }
    me.viewModel.attr('sources', sources);

    me.viewModel.attr('tabs', "definition");

    me.viewModel.attr("restrictionMobileFlag", "all");
    me.viewModel.on("restrictionMobileFlag", me.updateMobileRestriction.bind(me));

    me.viewModel.attr("restrictionSourceFlag", "all");
    me.viewModel.on("restrictionSourceFlag", me.updateSourceRestriction.bind(me));

    me.viewModel.attr("dataModel").on("customerId", me.updateRoleList.bind(me));

    if (RIGHTS("ldap.providerUrl")) {
      me.viewModel.attr("ldap", true);

      if (DATA_AUTHORIZED("user") && !APP("ldapUser")) {
        LdapUser.findAll().done((users) => {
          me.viewModel.attr("ldapUsers", users);
        });
      }
    }

    me.viewModel.attr("cannotChangePassword", me.viewModel.attr("_cmp.creation")
      || me.viewModel.attr('ldap'));

    me.viewModel.attr('onBeforeDeleteUserGroup', me.proxy(me.onBeforeDeleteUserGroup));

    me.viewModel.attr('dateParamsOptions', [{
      value: 'lastDays', text: me.msg('lastDays')
    },
      {value: 'between', text: me.msg('between')}
    ]);

    me.viewModel.attr('dateParams', {});
    me.viewModel.on('dateParams', me.changeParams.bind(me));


    me.viewModel.attr('geoParamOptions', ['union', 'intersection']);
  },

  changeParams: function () {
    let me        = this,
        dateParam = me.viewModel.attr('dateParams');

    switch (dateParam) {
      case 'lastDays':
        me.viewModel.attr('dateParamsBetween', false);
        me.viewModel.attr('dateParamsLastDays', me.viewModel.attr('dataModel.restriction').attr('period'));
        break;
      case 'between':
      default:
        me.viewModel.attr('dateParamsBetween', me.viewModel.attr('dataModel.restriction').attr('period'));
        me.viewModel.attr('dateParamsLastDays', false);

        if (me.viewModel.attr('dataModel').attr('restriction').attr('from') === " ") {
          me.viewModel.attr('dataModel').attr('restriction').attr('from', new Date());
        }

        if (me.viewModel.attr('dataModel').attr('restriction').attr('to') === " ") {
          me.viewModel.attr('dataModel').attr('restriction').attr('to', new Date());
        }
        break;
    }

  },

  updateRoleList: function () {
    let me                = this,
        authorizedRoleIds = RIGHTS("data.user.roleList"),
        customerId,
        customer,
        domain,
        roleIds,
        roles;

    APP().whenData("customer").done(() => {
      customerId = me.viewModel.attr("dataModel.customerId") || APP().user.customerId;
      customer = APP("customer", customerId);
      domain = customer ? APP("applicationDomains", customer.domainId) : null;
      roleIds = domain.roles ? _.intersection(authorizedRoleIds, domain.roles) : authorizedRoleIds;
      roles = roleIds ? APP("applicationRoles").filterByIdList(roleIds) : null;

      if (roles) {
        me.viewModel.attr("roleOptions", roles);
      }
    });

    if (DATA_AUTHORIZED("customer") && !APP("customer")) {
      Customer.findAll();
    }
  },

  onResetPassword: function () {
    let me = this;

    ChangePasswordPage.openPage({
      user:        APP("user", me.data.id),
      oldRequired: false
    });
  },

  onSendMessage: function () {
    let me = this;

    SendMessagePage.openPage({
      user:        APP("user", me.data.id),
      oldRequired: false
    });
  },

  updateMobileRestriction: function () {
    let me   = this,
        flag = me.viewModel.attr("restrictionMobileFlag");

    me.viewModel.attr("dataModel.restriction.mobile", flag === "mobile");
    me.viewModel.attr("dataModel.restriction.fleet", flag === "fleet");
  },

  updateSourceRestriction: function () {
    let me   = this,
        flag = me.viewModel.attr("restrictionSourceFlag");

    me.viewModel.attr("dataModel.restriction.source", flag === "source");
  },

  updateViewModel: function () {
    let me         = this,
        mobileFlag = me.data.restriction.mobile ? "mobile" : null,
        fleetFlag  = me.data.restriction.fleet ? "fleet" : null,
        sourceFlag = me.data.restriction.source ? "source" : null;

    me._super();
    me.viewModel.attr('passwordCmpConfig', APP().getPasswordCheckSettings());
    me.viewModel.attr("restrictionMobileFlag", mobileFlag || fleetFlag || "all");
    me.viewModel.attr("restrictionSourceFlag", sourceFlag || "all");

    if (me.data.licenceStartDate === "null") {
      me.viewModel.attr('dataModel.licenceStartDate', "");
    }

    if (me.data.licenceExpireDate === "null") {
      me.viewModel.attr('dataModel.licenceExpireDate', "");
    }

    // THM-10092: activer compte par défaut à la création de l'utilisateur
    if (me.viewModel.attr("showDeactivation") && _.isNil(me.data.active)) {
      me.viewModel.attr("dataModel.active", true);
    }

    me.viewModel.attr("cannotChangePassword", me.viewModel.attr("_cmp.creation")
      || (me.viewModel.attr('ldap') && me.data.roleName !== "Super Administrator"));


    if (me.viewModel.attr('dataModel').attr('restriction').attr('period')) {
      me.viewModel.attr('dateParams', me.viewModel.attr('dataModel').attr('restriction').attr('validStartDate') ? 'between' : 'lastDays');
      me.changeParams();
    }

    me.viewModel.attr('dataModel.restriction').on('period', me.changeParams.bind(me));

    if(me.data.restriction.geo && !me.data.authorizedZonesOperator){
      me.viewModel.attr('dataModel.authorizedZonesOperator', 'union');
    }

  },

  /**
   * @override
   */
  loadData: function (data, creation) {
    let me = this;

    if (!data.restriction) {
      data.attr("restriction", {});
    }
    me._super(data, creation);
  },

  /**
   * @override
   */
  getDataModelForSave: function () {
    let me   = this,
        data = me.getDataModel();

    data.email = data.email?.toLowerCase();
    return data;
  },

  /**
   * override
   * @returns {*}
   */
  checkErrors: function () {
    let me              = this,
        params          = me.viewModel.attr('dataModel'),
        newPassword     = params.passwordCreation,
        confirmPassword = params.passwordCreationConfirm,
        numberId        = params.numberId,
        email           = params.email,
        emailLowerCase  = email?.toLowerCase(),
        description     = params.description,
        errors          = me._super(),
        security        = RIGHTS('security.passwordCheck');

    if (errors) {
      return errors;
    }

    if (me.userNameAlreadyUsed(params)) {
      Toastr.showError(me.msg('userNameAlreadyUsed'), 3000);
      return true;
    }

    if (me.creation && (!me.viewModel.attr('ldap') && (!confirmPassword || !newPassword))) {
      Toastr.showToastInfo('EmptyPassword', 3000);
      return true;
    }
    if (!newPassword || newPassword.length <= 0) {
      delete params.passwordCreation;
      delete params.passwordCreationConfirm;
    } else {
      me.viewModel.dataModel.attr('changePassword', true);
      if (/*RIGHTS("center.securePwd")*/ security) {
        if (newPassword.length < security.minSize) {
          Toastr.showToastInfo("passwordRules", 3000);
          return true;
        }
        if ((newPassword.match(/[0-9]/g) || []).length < security.numeric) {
          Toastr.showToastInfo("passwordRules", 3000);
          return true;
        }
        if ((newPassword.match(/[A-Z]/g) || []).length < security.uppercase) {
          Toastr.showToastInfo("passwordRules", 3000);
          return true;
        }
        if ((newPassword.match(/[a-z]/g) || []).length < security.lowercase) {
          Toastr.showToastInfo("passwordRules", 3000);
          return true;
        }
        if ((newPassword.match(/[@#!$%]/g) || []).length < security.special) {
          Toastr.showToastInfo("passwordRules", 3000);
          return true;
        }
        if (newPassword !== confirmPassword) {
          Toastr.showError(me.msg("passwordConfirmUnmatch"), 3000);
          return true;
        }
      }
    }

    if (numberId && isNaN(numberId)) {
      Toastr.showError(me.msg("numberIdNotaNumber"), 3000);
      return true;
    }

    if (description && description.length > 240) {
      Toastr.showError(me.msg("descriptionTooLong"), 3000);
      return true;
    }

    if (emailLowerCase && emailLowerCase !== "" && !MaskFormat.FORMATERS.email.maskVerif.test(emailLowerCase)) {
      Toastr.showToastr('error', 'invalidEmail');
      return true;
    }

  },

  userNameAlreadyUsed: function (params) {
    return APP('users').datas.filter((user) => user.login === params.login && user.id !== params.id).length > 0;
  },

  onBeforeDeleteUserGroup: function (uiListModel, el, event, beforeContext) {
    let me         = this,
        deferred   = new $.Deferred(),
        requestDef = new $.Deferred();

    UserGroupConfirmationPopup.openSingleton({
      title:    me.msg('beforeDeleteTitle'),
      text:     me.msg('waitingForLinkedObject'),
      longText: true,
      buttons:  [
        {action: "userOnly", label: me.msg('userOnly'), style: "btn-ok"},
        {action: "all", label: me.msg('allObjects'), style: "btn-ok"},
        {action: "cancel", label: me.msg('cancel'), style: "btn-cancel"}
      ],
      deferred: requestDef,
      onAction: function (action) {
        if (action === "userOnly") {
          me.viewModel.attr('dataModel').attr('deleteOnlyUser', true);
          deferred.resolve();
          me.onSave(false);
        } else if (action === 'all') {
          deferred.resolve();
        } else {
          deferred.reject();
        }
      }
    });
    beforeContext.deferred = deferred;

    UserGroup.getLinkedObjects(beforeContext.item.id, 'user', me.data.id).done((res) => {
      if (!Object.keys(res).length) {
        requestDef.resolve(me.msg('noLinkedObjects', beforeContext.item.login));
      } else {
        requestDef.resolve(me.msg('returnLinkedObjectUser', beforeContext.item.login, UserGroup.formatLinkObjetctsResponse(res)));
      }
    });
  },

  /**
   * @override
   */
  createSaveItem: function (data) {
    let me = this;

    //data = _.extend(data, {userRestrictionEnabled: false});

    data.newLogin = data.login;
    delete data.login;

    if (!!me.creation) {
      data.newPassword = data.passwordCreation;
      _.each(["passwordCreation", "passwordCreationConfirm"], function (d) {
        delete data[d];
      });
    }

    if (_.isNil(data.accessMode)) {
      data.accessMode = 0;
    }

    if (_.isNil(data.licenceStartDate)) {
      data.licenceStartDate = "";
    }

    if (_.isNil(data.licenceExpireDate)) {
      data.licenceExpireDate = "";
    }

    if (me.viewModel.attr('dateParams') === 'lastDays') {
      delete data.restriction.validStartDate;
      delete data.restriction.validStartDateMillis;
      delete data.restriction.validEndDate;
      delete data.restriction.validEndDateMillis;
    } else {
      delete data.restriction.validDays;
    }

    return me._super(data)
  }

});

export default UserInfoPanel;
