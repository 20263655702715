import _ from "core-cmp/lodash";
import Toastr from "core-cmp/Toastr";
import {APP, RESOURCE_CLASS} from "core-uda/model/Resource";
import {I18N} from "core-cmp/util/I18n";
import Criteria from "core-criteria/Criteria";
import ResourceUI from "core-uda/ui/ResourceUI";
import SelectByStache from "thm/ui/common/criteria/ThmSelectByCriteria.stache";
import "core-criteria/selectBy/SelectByCriteria.css";

/**
 * @class ThmSelectByCriteria
 */
let ThmSelectByCriteria = Criteria.extend({

  /**
   * Read parameters value
   * @param {string} resource - resource name
   * @param {object} criteriaConfig - config
   * @param {string} criteriaName - criteria name
   * @returns {object|null} options
   */
  readParameters: function (resource, criteriaConfig, criteriaName) {
    let me           = this,
        capabilities = APP().getCapabilities(resource),
        parameters   = capabilities.parameters || {},
        value        = parameters[criteriaName],
        options      = value ? value.split(",") : null;

    return options ? {options: _.map(options, _.trim)} : null;
  }


}, {
  typeOptions: "array",
  states:      "observable",
  resourceMap: "observable",
  updateFunction: "*",

  /**
   * @override
   * @param config
   */
  init: function (config) {
    let me = this;

    if (config.i18nPrefix) {
      config.i18nPrefix = _.isString(config.i18nPrefix) ? [config.i18nPrefix] : config.i18nPrefix;
    } else {
      config.i18nPrefix = [];
    }
    if (!config.label) {
      if (config.labelKey) {
        config.label = I18N.msg(config.labelKey);
      } else if (config.i18nPrefix) {
        config.label = I18N.msg(config.i18nPrefix + "selection");
      }
    }

    if (!me.stache) {
      me.stache = SelectByStache;
    }

    me._super(config);

    me.validateMandatory = false;
    me.criteriaFormModel = {
      type:   'all',
      mobile: [],
      fleet:  [],
      beacon: [],
      flag:   []
    };

    if (!me.excludeAllOption) {
      me.typeOptions = [{
        value: 'all',
        text:  I18N.msg("uda.mobile.all")
      }];
    } else {
      me.typeOptions = [];
    }

    me.states = {};
    me.resourceMap = {};

    me.criteriaFormModel.on("type", me.proxy(me.changeType));
    me.criteriaFormModel.on("mobile", me.proxy(me.checkMobilesListForHistoricDetails));
    me.changeType();
  },

  /**
   * @override
   */
  setOptions: function () {
    let me = this;

    _.each(me.options, (option) => {
      // Sinon fait planter le HCombo si présent plusieurs fois (et vérifie si "mobile" ou "fleet" ou "beacon")
      if (!me.resourceMap[option]) {
        me.resourceMap[option] = true;
        me.states[option] = false;
        me.typeOptions.push({
          value: option,
          text:  I18N.msg("uda." + option + ".selection")
        });
      }
    });

    me.changeType();
  },

  changeType: function () {
    let me   = this,
        type = me.criteriaFormModel.type;

    _.each(me.options, (option) => {
      me.states.set(option, type === option);
    });

    me.checkMobilesListForHistoricDetails();
  },


  /**
   * @override
   */
  hasErrors: function () {
    let me     = this,
        params = me.criteriaFormModel,
        type   = params.type;

    if (type !== "all" && _.isEmpty(params[type])) {
      Toastr.showToastr("warning", "empty" + _.upperFirst(type));
    }

    return false;
  },

  /**
   * @override
   */
  formatModelToUDA: function () {
    let me        = this,
        params    = me.criteriaFormModel,
        resourceName = !_.isNil(me.resource) ? me.resource + '.' : "",
        paramsUda = {},
        type      = params.type;

    if (me.formatModelToUDACustom) {
      paramsUda = me.formatModelToUDACustom(params);
    } else {
      if (type !== "all" && !_.isEmpty(me.criteriaFormModel[type])) {
        let ids = me.criteriaFormModel[type].join(",");
        paramsUda[resourceName + me.name] = params.type + ":" + ids;
      }
    }

    return paramsUda;
  },

  /**
   * @override
   */
  setParams: function (params) {
    let me = this,
        type,
        ids,
        parts;

    me.criteriaFormModel.type = 'all';
    if (params[me.name]) {
      parts = params[me.name].split(':');
      type = parts[0];
      ids = parts[1].split(',');

      if (!_.isNil(me.states[type])) {
        me.criteriaFormModel.type = type;
        me.criteriaFormModel[type] = ids;
      }
    }
  },

  /**
   * @override
   */
  getUdaExportData: function () {
    let me         = this,
        params     = me.criteriaFormModel,
        type       = params.type,
        typeOption = _.find(me.typeOptions, {value: type}),
        ids        = me.criteriaFormModel[type],
        data       = [];

    data.push({key: typeOption.text, value: ""});

    if (type !== "all") {
      _.each(APP(type).filterByIdList(ids), (item) => {
        data.push({key: "", value: ResourceUI.getLabel(type, item)});
      })
    }

    return data
  },

  checkMobilesListForHistoricDetails: function () {
    let me = this,
        isActive = (me.criteriaFormModel.type === "mobile")
          && (me.criteriaFormModel.mobile.length > 0 && me.criteriaFormModel.mobile.length <= 5);

    APP().trigger('deactivateHistoricDetails', [!isActive]);

  },

  onItemsUpdated: function (wc, dom, wcEvent, wcFieldList, item) {
    let me = this;
    me.trigger("itemsupdated", [wc, item]);

    if (me.updateFunction) {
      me.updateFunction();
    }
  }
});

export default ThmSelectByCriteria;
