import { canConstruct } from "core-cmp/can";
import { APP } from "core-uda/model/Resource";
import { THM_PREF } from "thm/ThmPreferences";
import { UDA_URL } from "core-uda/model/ResourceDAO";
import _ from "core-cmp/lodash";
import { RIGHTS } from "core-uda/Rights";
import { BASE_URL } from "core-uda/model/AjaxDAO";
import DECKGL from "core-cls-gis-deckgl";
import { LAYER_BUILDERS } from "core-gis/ol/layer/OLLayerCreator";
import { STYLE_RESOLVER } from "core-cls-gis-style/StyleResolver";
import GeotiffConstants from "core-geotiff/utils/GeotiffConstants";

DECKGL.register({ GIS, LAYER_BUILDERS, STYLE_RESOLVER });

const MERCATOR_Y = 20037508.342789244;

const ADD_UDA_TOKEN = (imageTile, src) => {
  imageTile.getImage().src = `${src}&${APP().getLastTokenParameter()}`;
}

const BASE_LAYERS = {
  "road": {
    visible: true,
    type: "TO SET",
    url: "TO SET",
    data: { icon: "road" },
    maxZoom: 19,
    zoomFactor: 2
  },
  "marine": {
    type: "group",
    wmsCapabilities: {
      url: "{UDA_URL}map/base/cmap?",
      excludedWmsLayers: ["borders"],
      requiredWmsLayers: ["cmap_base"],
      toUpdateGisLayer: ["marine.cmapTileWMS", "marine.cmapWMS"]
    },
    data: {
      icon: "boatAnchor"
    },
    layers: [{
      type: "tileWMS",
      data: {
        id: "marine.cmapTileWMS"
      },
      url: "TO SET",
      params: "TO SET",
      layerExtent: [-MERCATOR_Y, -15496570.7397, MERCATOR_Y, 15496570.7397], // Change extent to hide grey header and footer on map
      projection: "EPSG:3857",
      maxResolution: 15552, // after zoom level 3 (excluded)
      maxZoom: 19,
      zoomFactor: 2,
      tileSize: 1024,
      wrapX: true,
      attributions: '© <a href="https://www.c-map.com">C-MAP</a>'
    }, {
      url: 'osm-cmap/{z}/{x}/{y}.png',
      data: {
        id: "marine.osmTileXYZ"
      },
      minResolution: 15552, // before zoom level 3 (included)
      //maxZoom:       3,
      zoomFactor: 2,
      type: "tileXYZ"
      //no attributions as they are not from C-MAP
    }, {
      type: "WMS",
      data: {
        id: "marine.cmapWMS"
      },
      url: "TO SET",
      params: "TO SET",
      layerExtent: [-MERCATOR_Y, -15496570.7397, MERCATOR_Y, 15496570.7397], // Change extent to hide grey header and footer on map
      projection: "EPSG:3857",
      maxResolution: 15552, // after zoom level 3 (excluded)
      maxZoom: 19,
      zoomFactor: 2,
      wrapX: true,
      attributions: '© <a href="https://www.c-map.com">C-MAP</a>'
    }]
  },
  "ordonanceSurveyUk": {}, // form center parameter using Yaml
  "roadEvfr": {}, // form center parameter using Yaml
  "roadOsni": {}, // form center parameter using Yaml
  "admiralty": {}, // form center parameter using Yaml
  "imagery": {
    type: "tileXYZ",
    url: "TO SET",
    data: { icon: "satellite" },
    maxZoom: 19,
    zoomFactor: 2
  },
  "hybrid": {
    type: "tileXYZ",
    url: "TO SET",
    data: { icon: "hybrid" },
    maxZoom: 19,
    zoomFactor: 2
  },
  "imageryBlueMarble": {
    type: "tileWMS",
    url: RIGHTS("map.layers.southPole.url", "https://geoserver.cls.fr/geoserver/wms"),
    data: { icon: "boatAnchor" },
    params: {
      LAYERS: "cls:noaa_blue_marble"
    },
    projection: "EPSG:900913",
    tileSize: 512,
    maxZoom: 8
  },
  "northPole": {
    type: "tileWMS",
    url: RIGHTS("map.layers.northPole.url", "https://geoserver.cls.fr/geoserver/wms"),
    data: { icon: "northPole" },
    params: {
      LAYERS: "cls:noaa_blue_marble_north_pole"
    },
    projection: "EPSG:32661",
    tileSize: 512,
    maxZoom: 8
  },
  "southPole": {
    type: "tileWMS",
    url: RIGHTS("map.layers.southPole.url", "https://geoserver.cls.fr/geoserver/wms"),
    data: { icon: "suhPole" },
    params: {
      LAYERS: "cls:noaa_blue_marble_south_pole"
    },
    projection: "EPSG:32761",
    tileSize: 512,
    maxZoom: 8
  },
  "bathymetry": {
    data: { icon: "isoline" }
  },
  "southPoleCCAMLR": {
    data: { icon: "southPole" },
    params: {
      TRANSPARENT: true,
      LAYERS: 'gis:hillshade_and_bathymetry,gis:coastline_sub,gis:statistical_areas,gis:subareas'
    },
    projection: "EPSG:102020"
  },
};

const VIEW_LAYERS = {
  'GmapRoad': {
    type: "baseView",
    typeId: "ROADMAP", // Type google (google.maps.MapTypeId)
    icon: "road",
    label: "Road"
  },
  'GmapSatellite': {
    type: "baseView",
    typeId: "SATELLITE", // Type google (google.maps.MapTypeId)
    icon: "satellite",
    label: "Imagery"
  },
  'GmapHybrid': {
    type: "baseView",
    typeId: "HYBRID", // Type google (google.maps.MapTypeId)
    icon: "hybrid",
    label: "Hybrid"
  }
}

const OVERLAY_LAYERS = ['nightAndDay', 'grid', 'scaleLine', 'metarTaf', {
  id: "eez",
  type: "tileWMS",
  url: 'TO SET',
  data: { icon: "zones" },
  params: {
    LAYERS: "cls:eez"
  },
  proj: "EPSG:3857",
  tileSize: 512,
  maxZoom: 19,
  minZoom: 2
}, {
    id: "ices",
    type: "tileWMS",
    url: 'TO SET',
    data: { icon: "zones" },
    params: {
      LAYERS: "cls:ices"
    },
    proj: "EPSG:3857",
    tileSize: 1024,
    maxZoom: 19,
    minZoom: 2
  }, {
    id: "fao",
    type: "tileWMS",
    url: 'TO SET',
    data: { icon: "zones" },
    params: {
      LAYERS: "cls:fao"
    },
    proj: "EPSG:3857",
    tileSize: 1024,
    maxZoom: 19,
    minZoom: 2
  },{
  id: 'geotiffs',
  data: {},
  type: 'group',
  // At the init, 8 layers without source for the moment (data is async retrieved somewhere else)
  layers: [...new Array(GeotiffConstants.MAX_NB_GEOTIFFS)].map((v,idx) => ({type: "staticImage", id: `${GeotiffConstants.LAYER_PREFIX}${idx}`, data: { id: `${GeotiffConstants.LAYER_PREFIX}${idx}` },})),
}];

const DECK_LAYER = {
  type: "deckgl",
  id: "deck",
  deckLayers: [
    {
      id: "driftZones", // study zone, Ex : zone sargasse
      type: "Polygon",
      pickable: true
    },
    {
      id: "driftInitialZones",
      type: "GeometryCollection", // Polygon, lines or points
      pickable: true
    },
    {
      id: "driftTrajectories",
      type: "LineString",
      pickable: true
    },
    {
      id: "driftInitialParticles",
      type: "Point",
      pickable: true
    },
    {
      id: "driftParticles",
      type: "Point",
      pickable: true
    },
    {
      id: "sarVoyage",
      type: "LineString",
      pickable: false
    },
    {
      id: "sarTrack",
      type: "LineString",
      pickable: false
    },
    {
      id: "sarTrackArrows",
      type: "Icon",
      pickable: false
    },
    {
      id: "sarEntry",
      type: "Icon",
      pickable: false
    },
    {
      id: "sarSruPosition",
      type: "Icon",
      pickable: false
    },
  ]
}

// THM-13166: webgl style
const predefinedStylesParticles = {
  circles: {
    symbol: {
      symbolType: "circle",
      size: 8,
      color: (f) => "#9B59B6",
      rotateWithView: false,
      offset: [0, 0],
      opacity: 0.6
    }
  }
};

const VECTOR_LAYERS = ['heatMap', 'gridMap', 'backInfo', 'radars', 'zones', 'selectionLayer', {
  id: 'mobiles',
  type: 'group',
  childIds: ['mobiles.trajectories', 'mobiles.server']
}, 'radarsEdit', 'animation', 'animationOverlay', 'collectedDatas', 'alerts', 'sensorMessages',
  'sarAlertsLines', 'sarAlerts', 'sarSruBasePositions', 'sarSRUnits', 'mobidriftHeatMap', 'meteoStationRecord', 'mobidriftHeatMap', 'mobidriftGridMap',/* DECK_LAYER, */ 'sarDrift', 'sarSad', 'sarSac', 'sarOperation', 'sarSacTrajectory',
  'sarSearch', "measures", "starMeasures", 'drawMeasureLayer', 'drawLayerWrapX', 'drawLayer', 'info', 'satellites', 'flagOnPosition', DECK_LAYER];

let ThmUmvMapConfig = canConstruct.extend({

  marineLayers: ["tileWMS", "tileXYZ", "WMS"],

  /**
   * Retourne la configuration pour les layers de fonds : baseLayers.
   * @returns {*|Object}
   */
  getBaseLayers: function () {
    let me = this,
      roadUrl = RIGHTS("map.layers.road.url", "OSM"),
      isOSM = (roadUrl === "OSM"),
      isBingRoad = (roadUrl.indexOf("BING") > -1),
      rightsLayers = RIGHTS('map.baseLayers', "road").split(","), // Base Layers autorisés
      baseLayerPref = THM_PREF.get("baseLayer") || "none", // Préférence pointant vers le dernier baseLayer actif
      tileSize = me.getTileSizeFromRight(),
      baseLayers,
      imageryUrl,
      hybridUrl,
      centerZoomFactor = RIGHTS('map.layers.zoomFactor', 1),
      viewLayerList = me.getViewLayers();

    // On complète la configuration en fonction des paramètres centre
    _.extend(BASE_LAYERS.marine.layers[0], {
      url: UDA_URL + "map/base/cmap?application=umv",
      tileLoadFunction: ADD_UDA_TOKEN,
      maxZoom: RIGHTS("map.layers.marine.maxZoom", 19) * centerZoomFactor,
      zoomFactor: centerZoomFactor === 1 ? 2 : 1.5,
      tileSize: tileSize,
      visible: PREF('cmapLoadMode') === "tileWMS",
      params: {
        LAYERS: "S52-Base" + ((!!THM_PREF.get("cmapLayers")) ? "," + THM_PREF.get("cmapLayers") : "")
      }
    });
    _.extend(BASE_LAYERS.marine.layers[2], {
      url: UDA_URL + "map/base/cmap?application=umv",
      tileLoadFunction: ADD_UDA_TOKEN,
      maxZoom: RIGHTS("map.layers.marine.maxZoom", 19) * centerZoomFactor,
      visible: PREF('cmapLoadMode') === "WMS",
      zoomFactor: centerZoomFactor === 1 ? 2 : 1.5,
      params: {
        LAYERS: "S52-Base" + ((!!THM_PREF.get("cmapLayers")) ? "," + THM_PREF.get("cmapLayers") : "")
      }
    });

    _.extend(BASE_LAYERS.marine, {
      maxZoom: RIGHTS("map.layers.marine.maxZoom", 19) * centerZoomFactor,
      zoomFactor: centerZoomFactor === 1 ? 2 : 1.5
    });

    _.extend(BASE_LAYERS.road, {
      url: roadUrl,
      type: isOSM ? "OSM" : isBingRoad ? roadUrl : "tileXYZ",
      maxZoom: 19 * centerZoomFactor,
      zoomFactor: centerZoomFactor === 1 ? 2 : 1.5
    });

    const udaHeaderToken = {};
    udaHeaderToken[APP().tokenHeaderName] = () => APP().getLastToken();

    const yamlOptions = {
      centerZoomFactor,
      baseUrl: _systemConfig_mapBaseUrl || BASE_URL,
      udaUrl: UDA_URL,
      udaHeaderToken
    };

    me.addBaseLayerFromYaml("roadEvfr", yamlOptions);
    me.addBaseLayerFromYaml("roadOsni", yamlOptions);
    me.addBaseLayerFromYaml("ordonanceSurveyUk", yamlOptions);
    me.addBaseLayerFromYaml("admiralty", yamlOptions);

    _.extend(BASE_LAYERS.imagery, {
      maxZoom: 19 * centerZoomFactor,
      zoomFactor: centerZoomFactor === 1 ? 2 : 1.5
    });
    imageryUrl = RIGHTS("map.layers.imagery.url", "");
    if (imageryUrl) {
      BASE_LAYERS.imagery.url = imageryUrl;
    } else {
      delete BASE_LAYERS.imagery;
    }
    if (_.includes(imageryUrl, 'tileWMS')) {
      const arr = imageryUrl.split(" ");
      if (arr.length > 1) {
        BASE_LAYERS.imagery.type = arr[0]
        BASE_LAYERS.imagery.url = arr[1]
      }
    }
    if (imageryUrl.indexOf("BING") > -1 || imageryUrl.indexOf("sentinel") > -1) {
      BASE_LAYERS.imagery.type = imageryUrl;
    }

    _.extend(BASE_LAYERS.hybrid, {
      maxZoom: 19 * centerZoomFactor,
      zoomFactor: centerZoomFactor === 1 ? 2 : 1.5
    });

    hybridUrl = RIGHTS("map.layers.hybrid.url", "");
    if (hybridUrl) {
      BASE_LAYERS.hybrid.url = hybridUrl;
    } else {
      delete BASE_LAYERS.hybrid;
    }

    if (hybridUrl.indexOf("BING") > -1) {
      BASE_LAYERS.hybrid.type = hybridUrl;
      BASE_LAYERS.hybrid.maxZoomBeforeStretching = Number.parseInt(RIGHTS('map.layers.hybrid.bingMaxZoom', 19));
    }

    _.extend(BASE_LAYERS.bathymetry, {
      maxZoom: 19 * centerZoomFactor,
      zoomFactor: centerZoomFactor === 1 ? 2 : 1.5
    });

    // Pour les layers parameters de format : type url
    let layerNames = ["bathymetry", "southPoleCCAMLR"];
    _.each(layerNames, function (layerName) {
      let params = RIGHTS("map.layers." + layerName + ".url", "");
      if (params) {
        let arr = params.split(" ");
        if (arr[0]) {
          BASE_LAYERS[layerName].type = arr[0];
        }
        if (arr.length > 1 && arr[1]) {
          BASE_LAYERS[layerName].url = arr[1];
        }
      } else {
        delete BASE_LAYERS[layerName];
      }

    });

    // On conserve que les layers autorisés
    baseLayers = _.pick(BASE_LAYERS, rightsLayers);

    // On positionne la visibilité des layers à partir de la préférence
    _.each(baseLayers, (config, id) => {
      config.visible = (baseLayerPref === id);
      config.id = id;
      if (!config.data) {
        config.data = {};
      }
      config.data.label = config.data.label || config.label || _.upperFirst(config.id); // Pour le I18n
    });

    let baseLayerList = _.values(baseLayers);

    // Ensure that at least one base or view layer is visible
    if (!_.find(baseLayerList.concat(viewLayerList), { visible: true }) && baseLayerList.length) {
      baseLayerList[0].visible = true;
    }

    return baseLayerList;
  },

  addBaseLayerFromYaml: function (key, options) {
    const config = GIS.loadLayerConfigFromYaml(RIGHTS("map.layers." + key, ""), options)
    if (config) {
      BASE_LAYERS[key] = config;
    }
  },

  /**
   * Retourne la configuration pour les layers de fonds de type vue (google) : viewLayers.
   * @returns {*|Object}
   */
  getViewLayers: function () {
    let rightsLayers = RIGHTS("map.baseLayers", "road").split(","), // Base Layers autorisés
      baseLayerPref = THM_PREF.get("baseLayer") || "none", // Préférence pointant vers le dernier baseLayer actif
      viewLayers;

    // On conserve que les layers autorisés
    viewLayers = _.pick(VIEW_LAYERS, rightsLayers);

    // On positionne la visibilité des layers à partir de la préférence
    _.each(viewLayers, (config, id) => {
      config.visible = (baseLayerPref === id);
      config.id = id;
    });

    return _.values(viewLayers);
  },

  /**
   * Retourne la configuration pour les layers informatifs : overlayLayers.
   * @returns {*|Object}
   */
  getOverlayLayers: function () {
    let layers       = [],
        rightsLayers = RIGHTS("map.overlayLayers", "nightAndDay,grid,scaleLine,eez,ices,fao").split(","),
        overlayLayers;

    if (RIGHTS('data.geoTiff.authorized') && RIGHTS('data.geoimages.accept')) {
      rightsLayers.push('geotiffs');
    }

    overlayLayers = _.filter(OVERLAY_LAYERS, (layer) => rightsLayers.indexOf(layer.id || layer) >= 0);

    for (let layer of overlayLayers) {
      if (layer.type === "tileWMS" || layer.type === "tileWMTS") {
        layer.visible = THM_PREF.get("wmsLayer-" + layer.id);
        layer.url = RIGHTS("map.layers.geoserver.url");
        layers.push(layer);
      } else if (layer.type === "group") {
        layer.visible = true;
        layers.push(layer);
      } else {
        layers.push({
          id:      layer,
          visible: THM_PREF.get("overlayLayer-" + layer)
        })
      }
    }

    return layers;
  },

  /**
   * Retourne la configuration pour les layers métier : vectorLayers.
   * @returns {*|Object}
   */
  getVectorLayers: function () {
    return VECTOR_LAYERS;
  },

  /**
   * Returns sub layers for a given layer
   */
  getBaseLayersConf: function (layer) {
    let me = this;

    return me[layer + 'Layers'];
  },

  getTileSizeFromRight: function () {
    let me = this,
      url = RIGHTS("map.base.cmap.config"),
      right = url.split(":"),
      size = 512;

    size = right[1] ? right[1] : size;

    return size;
  }


});

const MAP_CONFIG = new ThmUmvMapConfig();

export { MAP_CONFIG };
export default ThmUmvMapConfig;
